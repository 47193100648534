import {ExternalLink} from 'components/ExternalLink';
import {ArrowUpIcon} from '@heroicons/react/20/solid';

type CTAButtonProps = {
  title: string;
  description: string;
  url: string;
};

export const CTAButton: React.FC<CTAButtonProps> = ({
  title,
  description,
  url,
}) => {
  return (
    <div className="rounded border border-gray-800 bg-gray-900 bg-opacity-90 p-4 shadow-lg">
      <div className="flex items-center justify-between">
        <div>
          <div className="font-semibold text-bl-primary">{title}</div>
          <div className="hidden text-sm font-medium text-gray-300 sm:block">
            {description}
          </div>
        </div>

        <ExternalLink className="px-4 text-sm text-white" href={url}>
          <span className="flex rotate-90">
            <ArrowUpIcon className="h-8 w-8 animate-bounce" />
          </span>
        </ExternalLink>
      </div>
    </div>
  );
};
