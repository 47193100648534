import React from 'react';

import {Tab as ReactTab} from '@headlessui/react';
import FadeInV2 from '@/components/redesign/FadeInV2';
import {useBreakpoints} from '@/utils/hooks';
import Image from 'next/legacy/image';

type VideoFormat = 'video/webm' | 'video/mp4';

type Videos = {
  url: string;
  format: VideoFormat;
};

export type Tab = {
  key: string;
  tabName: string;
  description: React.ReactNode;
  heading: string;
  videos: Videos[];
  imageUrl: string;
};

const Tabs = ({
  className = '',
  tabs,
  title,
}: {
  className?: string;
  title?: string;
  tabs: Tab[];
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const {isMobile} = useBreakpoints();

  return (
    <div className={`${className} py-16 lg:py-20`}>
      <ReactTab.Group
        selectedIndex={selectedTabIndex}
        onChange={setSelectedTabIndex}
      >
        <ReactTab.List className="flex flex-shrink-0 flex-col gap-9 sm:gap-12">
          <div className="flex flex-col items-start gap-4 text-grayscale-300 sm:gap-6">
            {title && (
              <FadeInV2 direction="none" duration={1400} threshold={1}>
                <div className="shib-h2 flex px-9 lg:flex-shrink-0 lg:px-16">
                  {title}
                </div>
              </FadeInV2>
            )}
            <FadeInV2
              className="w-full lg:w-auto"
              direction="down"
              duration={1400}
              threshold={1}
            >
              <div className="z-20 flex w-full flex-shrink-0 flex-col flex-nowrap items-center gap-4 px-9 lg:flex-row lg:px-16">
                {tabs.map(({key, tabName}) => {
                  return (
                    <ReactTab
                      className={({selected}) =>
                        `border-1 text-lg-button w-full whitespace-nowrap rounded-[36px] border px-10 py-3 transition-colors duration-500 ease-in sm:py-4 ${
                          selected
                            ? 'border-grayscale-300 text-grayscale-300'
                            : 'border-grayscale-500 text-grayscale-500'
                        }`
                      }
                      key={key}
                    >
                      {tabName}
                    </ReactTab>
                  );
                })}
              </div>
            </FadeInV2>
          </div>
          {tabs.map(({key, heading, description, videos, imageUrl}) => {
            return (
              <ReactTab.Panel key={key}>
                <div className="flex min-h-[400px] flex-col items-center gap-9 sm:gap-12">
                  {isMobile ? (
                    <FadeInV2
                      className="ease-out w-full"
                      direction="right"
                      duration={1400}
                    >
                      <Image
                        src={imageUrl}
                        alt={key}
                        layout="responsive"
                        width={360}
                        height={157}
                        className="w-full"
                        objectFit="cover"
                      />
                    </FadeInV2>
                  ) : (
                    <FadeInV2
                      className="ease-out"
                      direction="right"
                      duration={1400}
                    >
                      <video
                        autoPlay
                        playsInline
                        muted
                        loop
                        preload="auto"
                        className="h-[400px] w-screen object-cover"
                      >
                        {videos.map(({url, format}) => (
                          <source src={url} type={format} key={url} />
                        ))}
                      </video>
                    </FadeInV2>
                  )}
                  <FadeInV2
                    className="ease-out"
                    direction="left"
                    duration={1400}
                  >
                    <div className="grid h-fit grid-rows-1 gap-4 px-9 sm:gap-12 lg:grid-cols-2 lg:px-16">
                      <p className="shib-subtitle text-grayscale-300">
                        {heading}
                      </p>
                      <div className="shib-text-description flex flex-col gap-6 text-grayscale-400 sm:grid sm:grid-cols-2 sm:gap-12">
                        {description}
                      </div>
                    </div>
                  </FadeInV2>
                </div>
              </ReactTab.Panel>
            );
          })}
        </ReactTab.List>
      </ReactTab.Group>
    </div>
  );
};
export default Tabs;
