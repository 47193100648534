import React, {ReactNode} from 'react';
import Link from 'next/link';

import {useBreakpoints} from '@/utils/hooks';
import ButtonV2 from '@/components/redesign/ButtonV2';
import {DynamicImage} from '../DynamicImage';

type Show = {
  title: string;
  link: string;
  image: string;
  video: string;
  creator: string;
  description: ReactNode;
  viewCount: number;
  imageHeight: number;
  imageWidth: number;
  contributors: number;
};

const FEATURED_SHOWS: Show[] = [
  {
    title: 'White Rabbit',
    link: '/shows/white-rabbit',
    image:
      'https://storage.googleapis.com/shibuya-white-rabbit/white-rabbit-preview.png',
    imageWidth: 568,
    imageHeight: 350,
    video:
      'https://storage.googleapis.com/shibuya-white-rabbit/white-rabbit-preview-v2.webm',
    creator: 'pplpleasr and Maciej Kuciara',
    description: (
      <p>
        White Rabbit is an interactive series about the journey of going down
        the crypto rabbit hole. It follows Mirai, who embarks on a path to
        discover the meaning of free will and self-sovereignty.
      </p>
    ),
    viewCount: 128392,
    contributors: 8392,
  },
  {
    title: 'Dominion X',
    link: '/shows/dominionx',
    image:
      'https://storage.googleapis.com/shibuya-white-rabbit/dominionx-chicken-preview.jpg',
    video:
      'https://storage.googleapis.com/shibuya-white-rabbit/dom-x-reel.webm',
    imageHeight: 283.38,
    imageWidth: 460.5,
    creator: "Steve Aoki and Seth Green's Stoopid Buddy Stoodios",
    description: (
      <p>
        Steve Aoki and the studio behind “Robot Chicken” brings Dominion X:
        Level 2 to Shibuya. The six-week event features an interactive
        stop-motion animation short series.
      </p>
    ),
    viewCount: 128392,
    contributors: 8392,
  },
];

const FeaturedShowCard = ({
  show: {
    title,
    link,
    image,
    video,
    creator,
    description,
    imageHeight,
    imageWidth,
  },
}: {
  show: Show;
}) => {
  const {isMobile} = useBreakpoints();

  return (
    <div className="group ">
      <div className="relative">
        <DynamicImage
          height={imageHeight}
          width={imageWidth}
          layout="responsive"
          className="rounded-lg opacity-100 duration-500 ease-in lg:group-hover:opacity-0"
          src={image}
          alt={`${title} thumbnail`}
        />
        <div className="absolute top-0 aspect-[13/8] bg-black duration-500 ease-in group-hover:opacity-100 sm:opacity-0">
          <Link href={link}>
            {!isMobile ? (
              <ButtonV2
                category="translucent"
                size="sm"
                className="absolute left-1/2 top-1/2 z-10 m-auto max-w-max -translate-x-1/2 -translate-y-1/2"
                text="Go to show page"
              />
            ) : (
              <div className="absolute h-full w-full"></div>
            )}
          </Link>
          {isMobile ? (
            <DynamicImage
              className="h-full w-full rounded-lg bg-opacity-100"
              src={image}
              height={imageHeight}
              width={imageWidth}
              layout="responsive"
              alt={`${title} thumbnail`}
            />
          ) : (
            <video
              autoPlay
              playsInline
              muted
              loop
              preload="auto"
              className="h-full rounded-lg bg-opacity-100 object-cover opacity-70"
            >
              <source src={video}></source>
            </video>
          )}
        </div>
        <div className="mt-6 flex flex-col gap-1 pb-2 sm:mt-9 sm:gap-2 sm:pb-3">
          <div className="flex flex-col justify-between gap-1 sm:flex-row sm:items-center">
            <Link href={link} passHref legacyBehavior>
              <a>
                <h2 className="shib-subtitle cursor-pointer text-grayscale-300">
                  {title}
                  <span className="text-grayscale-400"> / {creator}</span>
                </h2>
              </a>
            </Link>
          </div>
        </div>
        <div className="shib-text-description flex w-full flex-col gap-4 text-grayscale-400 opacity-100 duration-500 ease-in group-hover:opacity-100 sm:opacity-0">
          {description}
        </div>
      </div>
    </div>
  );
};

const FeaturedShows = () => {
  return (
    <div className="flex w-full flex-col gap-9 px-9 sm:pt-20 md:px-16">
      <h1 className="shib-h2 text-grayscale-300">Featured shows</h1>
      <div className="grid grid-cols-1 gap-12 rounded-2xl lg:grid-cols-2 lg:gap-12">
        {FEATURED_SHOWS.map((show) => {
          return <FeaturedShowCard show={show} key={show.title} />;
        })}
      </div>
    </div>
  );
};

export default FeaturedShows;
